import React from "react";

const Footer = () => {
  return (
    <footer className="">
      <div className="px-12 py-12 w-full flex justify-center items-center">
        <small>© 2021 kotaroo.works</small>
      </div>
    </footer>
  );
}

export default Footer;
