import React, {useEffect, useState} from 'react';
import Layout from '../components/layout/Layout';

const IndexPage = () => {
  const odairoid = <a className="underline bg-yellow-300" href="https://twitter.com/odairoid_001/status/1424749146972459010?s=20">オダイロイド1号上にて募集しました( ´∀`)</a>;
  const monapachan = <a className="underline bg-yellow-300" href="https://twitter.com/monapachan/status/1436867409671950340?s=20">『モナパちゃん特別賞&#x1f3c6;』</a>;
  const monablesschan = <a className="underline bg-yellow-300" href="https://twitter.com/monablesschan/status/1436912399613784067?s=20">『モナブレスちゃん特別賞&#x1f3c6;』</a>;
  const bouhurachan = <a className="underline bg-yellow-300" href="https://twitter.com/ukorosi/status/1436918441634525187?s=20">『ボウフラちゃん特別賞&#x1f3c6;』</a>;
  const [bgColor, setBgColor] = useState(null);
  const alterStyle = (value) => {
    setBgColor(value);
  }
  useEffect(() => {
    setBgColor("#F0F0F0");
  }, []);

  return (
    <div >
      <Layout>
        <div className="px-4 md:px-16 dark:bg-gray-800 dark:text-gray-800">
          <div className="w-full h-full sm:py-8 mx-auto flex justify-center items-center">
            <div className='w-full font-bold'>
              <h1 className="text-4xl sm:text-6xl my-2 sm:my-16 flex justify-center">このサイトではMonapartyのフリーロゴを配布しています&#x1f389;</h1>
              <div className="py-4 sm:px-8 text-left break-all">
                <div className="sm:py-2">
                  <p className="text-lg sm:text-2xl text-left border-b-2">サイトについて</p>
                  <div className="py-2 text-sm sm:text-base leading-relaxed">
                    <p>モナコインとモナパーティーの関係を表すロゴイラストを{odairoid}</p>
                    <p>テーマを『MONAPARTY EXTENDS MONACOIN BLOCKCHAIN』とし、総勢14名の方達にご参加頂けました。また、特別賞として{monapachan} {monablesschan} {bouhurachan} も配布されました。</p>
                  </div>
                </div>
                <div className="sm:py-2">
                  <p className="text-lg sm:text-2xl text-left border-b-2">つかいかた</p>
                  <div className="py-2 text-sm sm:text-base leading-relaxed">
                    <p>自分のサイトやブログ等にシール感覚で貼り付けて使ってみよう&#x1f389;</p>
                  </div>
                </div>
                <div className="sm:py-2">
                  <p className="text-lg sm:text-2xl text-left border-b-2">利用規約</p>
                  <div className="py-2 text-sm sm:text-base">
                    <p className="leading-relaxed">当サイトで配布しているロゴは規約の範囲内であれば無料でご利用頂けます。全てのロゴの著作権、その他権利は作者が所有します。</p>
                    <p>また、以下の用途での利用はお断りします</p>
                    <ul className="list-disc list-inside leading-relaxed">
                      <li>公序良俗に反する目的での利用</li>
                      <li>反社会的勢力や違法行為に関わる利用</li>
                      <li>ロゴや作者、Monaparty のイメージを損なうような利用（攻撃的・差別的・侮辱的）</li>
                      <li>ロゴ自体をコンテンツ・商品として再配布・販売</li>
                      <li>その他著作者が不適切と判断した場合</li>
                    </ul>
                    <p>本規約は予告無く変更する場合があります。予めご了承下さい。</p>
                  </div>
                </div>
              </div>
              <div className="flex flex-wrap justify-end items-center">
                <div className="w-10 h-10 border cursor-pointer" style={{backgroundColor: "#2DA6F0"}} onClick={e => alterStyle("#2DA6F0")}></div>
                <div className="w-10 h-10 border cursor-pointer" style={{backgroundColor: "#F02D2D"}} onClick={e => alterStyle("#F02D2D")}></div>
                <div className="w-10 h-10 border cursor-pointer" style={{backgroundColor: "#C6F02D"}} onClick={e => alterStyle("#C6F02D")}></div>
                <div className="w-10 h-10 border cursor-pointer" style={{backgroundColor: "#FFFFFF"}} onClick={e => alterStyle("#FFFFFF")}></div>
                <label className="flex">
                  <input className="w-10 h-10 cursor-pointer" type="color" value={bgColor} onChange={e => alterStyle(e.target.value)} />
                  <p className="mt-2 sm:mt-0 text-xl sm:text-3xl cursor-pointer">{" "}&#x1f3a8;</p>
                </label>
              </div>
              <div style={{backgroundColor: bgColor}} className="my-2 sm:p-12 flex flex-wrap rounded-2xl">
                <LogoCard twitter="@0kotori" link="https://twitter.com/0kotori" src={"images/kotori_01.png"} award1="&#x1f3c6;コタロ賞&#x1f3c6;" />
                <LogoCard twitter="@amarin123go" link="https://twitter.com/amarin123go" src={"images/amarin_01.png"} />
                <LogoCard twitter="@d8bd31b" link="https://twitter.com/d8bd31b" src={"images/6416_01.jpg"} />
                <LogoCard twitter="@GemBoxWork" link="https://twitter.com/GemBoxWork" src={"images/gem_01.png"} award1="&#x1f3c6;モナパちゃん GO賞&#x1f3c6;" />
                <LogoCard twitter="@GemBoxWork" link="https://twitter.com/GemBoxWork" src={"images/gem_02.png"} award1="&#x1f3c6;モナパちゃん GO賞&#x1f3c6;" />
                <LogoCard twitter="@GemBoxWork" link="https://twitter.com/GemBoxWork" src={"images/gem_03.png"} award1="&#x1f3c6;モナパちゃん GO賞&#x1f3c6;" />
                <LogoCard twitter="@GemBoxWork" link="https://twitter.com/GemBoxWork" src={"images/gem_04.png"} award1="&#x1f3c6;モナパちゃん GO賞&#x1f3c6;" />
                <LogoCard twitter="@grifonbk" link="https://twitter.com/grifonbk" src={"images/izuru_01.png"} award1="&#x1f3c6;コタロ賞&#x1f3c6;" />
                <LogoCard twitter="@KATE_necology" link="https://twitter.com/KATE_necology" src={"images/kate_01.png"} award1="&#x1f3c6;モナパちゃん ZEN賞&#x1f3c6;" award2="&#x1f3c6;モナブレスちゃん賞&#x1f3c6;" />
                <LogoCard twitter="@KATE_necology" link="https://twitter.com/KATE_necology" src={"images/kate_02.png"} award1="&#x1f3c6;モナパちゃん ZEN賞&#x1f3c6;" award2="&#x1f3c6;モナブレスちゃん賞&#x1f3c6;" />
                <LogoCard twitter="@KATE_necology" link="https://twitter.com/KATE_necology" src={"images/kate_03.png"} award1="&#x1f3c6;モナパちゃん ZEN賞&#x1f3c6;" award2="&#x1f3c6;モナブレスちゃん賞&#x1f3c6;" />
                <LogoCard twitter="@KATE_necology" link="https://twitter.com/KATE_necology" src={"images/kate_04.png"} award1="&#x1f3c6;モナパちゃん ZEN賞&#x1f3c6;" award2="&#x1f3c6;モナブレスちゃん賞&#x1f3c6;" />
                <LogoCard twitter="@m0m0tak0" link="https://twitter.com/m0m0tak0" src={"images/momo_01.jpg"} />
                <LogoCard twitter="@MgPhjfXQamhFuXZ" link="https://twitter.com/MgPhjfXQamhFuXZ" src={"images/asterisk_01.png"} award1="&#x1f3c6;ボウフラちゃん賞&#x1f3c6;" />
                <LogoCard twitter="@momotan011" link="https://twitter.com/momotan011" src={"images/momota_01.png"} />
                <LogoCard twitter="@momotan011" link="https://twitter.com/momotan011" src={"images/momota_02.png"} />
                <LogoCard twitter="@monolag" link="https://twitter.com/monolag" src={"images/mekabu_01.png"} />
                <LogoCard twitter="@pointsute1" link="https://twitter.com/pointsute1" src={"images/neszta_01.png"} award1="&#x1f3c6;コタロ賞&#x1f3c6;" />
                <LogoCard twitter="@pointsute1" link="https://twitter.com/pointsute1" src={"images/neszta_02.png"} award1="&#x1f3c6;コタロ賞&#x1f3c6;" />
                <LogoCard twitter="@usagi_crypto" link="https://twitter.com/usagi_crypto" src={"images/usagi_01.png"} award1="&#x1f3c6;モナパちゃん SHOW賞&#x1f3c6;" />
                <LogoCard twitter="@usagi_crypto" link="https://twitter.com/usagi_crypto" src={"images/usagi_02.png"} award1="&#x1f3c6;モナパちゃん SHOW賞&#x1f3c6;" />
                <LogoCard twitter="@vip_a_no" link="https://twitter.com/vip_a_no" src={"images/ano_01.png"} />
                <LogoCard twitter="@vip_a_no" link="https://twitter.com/vip_a_no" src={"images/ano_02.png"} />
                <LogoCard twitter="@vip_a_no" link="https://twitter.com/vip_a_no" src={"images/ano_03.png"} />
                <LogoCard twitter="@zugaaanzubababa" link="https://twitter.com/zugaaanzubababa" src={"images/zugaan_02.jpg"} />
                <LogoCard twitter="@zugaaanzubababa" link="https://twitter.com/zugaaanzubababa" src={"images/zugaan_01.jpg"} />
              </div>
              <div className="flex flex-wrap justify-end items-center">
                <div className="w-10 h-10 border cursor-pointer" style={{backgroundColor: "#2DA6F0"}} onClick={e => alterStyle("#2DA6F0")}></div>
                <div className="w-10 h-10 border cursor-pointer" style={{backgroundColor: "#F02D2D"}} onClick={e => alterStyle("#F02D2D")}></div>
                <div className="w-10 h-10 border cursor-pointer" style={{backgroundColor: "#C6F02D"}} onClick={e => alterStyle("#C6F02D")}></div>
                <div className="w-10 h-10 border cursor-pointer" style={{backgroundColor: "#FFFFFF"}} onClick={e => alterStyle("#FFFFFF")}></div>
                <label className="flex">
                  <input className="w-10 h-10 cursor-pointer" type="color" value={bgColor} onChange={e => alterStyle(e.target.value)} />
                  <p className="mt-2 sm:mt-0 text-xl sm:text-3xl cursor-pointer">{" "}&#x1f3a8;</p>
                </label>
              </div>
            </div>
          </div>
        </div>
      </Layout>
    </div>
  );
}

const LogoCard = ({ twitter, link, src, award1, award2 }) => {
  return (
    <div className="flex flex-wrap content-end justify-center sm:w-1/2 lg:w-1/4 p-6">
      <a href= {src}>
        <img src={ src } />
      </a>
      <div className="rounded-t-xl bg-white bg-opacity-30 text-center text-xs lg:text-xs p-1 mt-2">
        <a className="text-base lg:text-lg" href={link}>({ twitter })</a>
        <p>{ award2 }</p>
        <p>{ award1 }</p>
      </div>
    </div>
  );
};

export default IndexPage;