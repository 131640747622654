import React from "react";
import "../../styles/global.css"
import Footer from './Footer';
import SEO from '../Seo';

const Layout = ({ children }) => {
  return (
    <>
      <SEO />
      <div>
        <div className="p-4 flex flex-col min-h-screen ">
          <main className="flex-grow">{children}</main>
          <Footer />
      </div>
      </div>
    </>
  );
};

export default Layout;
